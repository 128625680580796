import { RightOutlined } from '@ant-design/icons';
import { Breadcrumb, Card, Col, Row } from 'antd';
// import set1 from './images/Group 3041.svg';
import set2 from './images/set2.svg';
import set3 from './images/empSbg.svg';
// import set4 from './images/Group 31.svg';
import set5 from './images/Sbg.svg';
import set6 from './images/Iconn.svg';
import arrow from './images/Vector 9.svg';
import styles from './index.module.less';
import React from 'react';
import { Link } from 'react-router-dom';

const SettingsPage = () => {
  const settingsData = [
    // {
    //   title: 'BUs',
    //   subtitle: 'Configure BUs',
    //   navigation: '/settings/bu',
    //   image: set1
    // },
    // {
    //   title: 'SBUs',
    //   subtitle: 'Configure SBUs',
    //   navigation: '/settings/sbu',
    //   image: set5
    // },
    // {
    //   title: 'Projects',
    //   subtitle: 'Configure Projects',
    //   navigation: '/settings/projects',
    //   image: set4
    // },
    // {
    //   title: 'User Matrix',
    //   subtitle: 'Map users to designations',
    //   navigation: '/settings/designations-new',
    //   image: set3
    // },
    // {
    //   title: 'Designations',
    //   subtitle: 'Configure Designations',
    //   navigation: '/settings/designation',
    //   image: set2
    // },
    // {
    //   title: 'Designation Mapping',
    //   subtitle: 'Configure Designation Mapping',
    //   navigation: '/settings/designation-mapping',
    //   image: set3
    // },
    // {
    //   title: 'SBGs',
    //   subtitle: 'Configure SBGs',
    //   navigation: '/settings/sbg',
    //   image: set4
    // },
    // {
    //   title: 'Approval Roles',
    //   subtitle: 'Configure Approval Roles',
    //   navigation: '/settings/approver',
    //   image: set1
    // },
    {
      title: 'User Management',
      subtitle: 'Create and Manage User Management',
      navigation: '/settings/usermanagement',
      image: set3
    },
    {
      title: 'Categories',
      subtitle: 'Configure Categories',
      navigation: '/settings/categories',
      image: set5
    },
    {
      title: 'Sub-Categories',
      subtitle: 'Configure Sub-Categories',
      navigation: '/settings/sub-categories',
      image: set2
    },

    {
      title: 'DOA Flows',
      subtitle: 'Create and Manage DOA Flows',
      navigation: '/settings/doa-flows',
      image: set6
    }
  ];

  return (
    <>
      <div className={`${styles.breadcrub_container}`}>
        {' '}
        <Breadcrumb separator={<RightOutlined />}>
          <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Row className="mt-5">
        {settingsData?.map((item, index) => (
          <Col key={index} span={24}>
            <Link to={item.navigation}>
              <Card hoverable className={`mb-3 ${styles.settings_card}`}>
                <Row>
                  <Col span={20}>
                    <Row>
                      <Col>
                        {' '}
                        <img src={item.image} className="mr-2" />{' '}
                      </Col>

                      <Col span={10}>
                        <span className="sfprotext-bold font-14">
                          {item.title}
                        </span>
                        <br />
                        <span className="sfprotext-regular font-12">
                          {item.subtitle}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="df-jc-ac text-right" span={4}>
                    <img src={arrow} className="" />{' '}
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default SettingsPage;
