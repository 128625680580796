import * as React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from './common/Loader';
import { history } from './app/history';
import { getLoggedInUser } from 'utilities/helpers';
import Login from './pages/login';
import ResetPassword from './pages/Resetpassword';
import SetPassword from './pages/SetPassword';
import DashBoard from 'pages/dashboard';
import AdminManage from './pages/admin-manage';
import InitiateEnvelope1 from './pages/initiate-envelope';
import ReviewActions from './pages/review-actions';
import AdminManage1 from './pages/pending';
import Reports from './pages/reports';
import AdminDashboard from './pages/admin';
import Settings from './pages/settings';
import Categories from './pages/settings/components/Categories';
import SubCategories from './pages/settings/components/Sub-Categories';
import DoaList from './pages/settings/components/DOA-Flow';
import CreateDOAPage from './pages/settings/components/DOA-Flow/AddFlow';
import UserManagement from './pages/settings/components/Usermanagement';

const roleBasedNavigation = (id) => {
  switch (id) {
    case 'user':
      return '/dashboard';
    case 'admin':
      return '/admin-dashboard';
    // case 'qc_initiate':
    //   return '/dashboard';
    // case 'prod_user':
    //   return '/dashboard';
    // case 'update_admin':
    //   return '/backend_mrn';
    // case 'ppic_user':
    //   return '/dashboard';
    // case 'qa_super_user':
    //   return '/super_admin_dashboard';
    default:
      return '/';
  }
};
const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const { loading } = useSelector((state) => state.login);
  const { loggedIn } = getLoggedInUser();

  if (loading) {
    return <Loader />;
  }
  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const AuthChecker = ({ children }) => {
  let location = useLocation();

  const { loggedIn, user } = getLoggedInUser();

  let goTo = roleBasedNavigation(user?.role_id || '');

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return children;
};

function App() {
  // const user = getLoggedInUser();
  let goTo = '/login';
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <Login />
            </AuthChecker>
          }
        />
        <Route
          path="reset-password"
          element={
            <AuthChecker>
              <ResetPassword />
            </AuthChecker>
          }
        />
        <Route
          path="set-password"
          element={
            <AuthChecker>
              <SetPassword />
            </AuthChecker>
          }
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <DashBoard />
            </PrivateRoute>
          }
        />
        <Route
          path="manage"
          element={
            <PrivateRoute>
              <AdminManage />
            </PrivateRoute>
          }
        />
        <Route
          path="initiate-envelope-1"
          element={
            <PrivateRoute>
              <InitiateEnvelope1 />
            </PrivateRoute>
          }
        />
        <Route
          path="actions-required/review-actions/:id"
          element={
            <PrivateRoute>
              <ReviewActions />
            </PrivateRoute>
          }
        />
        <Route
          path="pending"
          element={
            <PrivateRoute>
              <AdminManage1 />
            </PrivateRoute>
          }
        />
        <Route
          path="reports"
          element={
            <PrivateRoute>
              <Reports />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-dashboard"
          element={
            <PrivateRoute>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/categories"
          element={
            <PrivateRoute>
              <Categories />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/sub-categories"
          element={
            <PrivateRoute>
              <SubCategories />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/doa-flows"
          element={
            <PrivateRoute>
              <DoaList />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/create-doa-flow"
          element={
            <PrivateRoute>
              <CreateDOAPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/usermanagement"
          element={
            <PrivateRoute>
              <UserManagement />
            </PrivateRoute>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
