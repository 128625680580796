import React from 'react';
import { Col, Form, Input, Row, Space, Select, Radio, Divider } from 'antd';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
// import { ExclamationCircleFilled } from '@ant-design/icons';
// import { styles } from './index.module.less';

const AdhocModal = ({
  handleCancel,
  isModalVisible,
  onFinish,
  loading,
  locations,
  functions,
  category,
  onCategoryChange,
  subcategory
}) => {
  const [form] = Form.useForm();
  const Option = Select;
  // const [isChecked, setIsChecked] = useState(false);
  // const toggleCheckbox = () => {
  //   setIsChecked(!isChecked);
  // };
  // const [locations, setLocations] = useState([]);
  // const [functions, setFunctions] = useState([]);
  // console.log('subcategory', subcategory);

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    // console.log('selectedValue', selectedValue);
    onCategoryChange(selectedValue);
  };

  return (
    <>
      <CustomModal
        width={1000}
        footer={null}
        onCancel={handleCancel}
        visible={isModalVisible}
        title="Initiate ad-hoc envelope"
      >
        {/* <Alert
        icon={<ExclamationCircleFilled />}
        showIcon
        message={<b>Caution</b>}
        className="mb-3"
        type="warning"
        description={
          <div
            style={{ fontWeight: 400, fontSize: '12px', marginLeft: '-40px' }}
          >
            Adhoc envelopes should only be utilized in the absence of a Standard
            DOA. Before proceeding with any ad-hoc DOA, ensure that you have
            thoroughly checked the Standard DOA. Bypassing the Standard DOA
            without proper justification may result in procedural errors and
            potential consequences.
          </div>
        }
      /> */}
        <Form
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
          onFinish={onFinish}
        >
          {/* <FormItem
          label="Envelope Name"
          name="envelope_name"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Input placeholder="Enter name" />
        </FormItem>

        <FormItem
          label="Description"
          name="description"
          rules={[
            {
              required: false
            }
          ]}
        >
          <Input.TextArea rows={5} allowClear placeholder="Enter description" />
        </FormItem>
        <FormItem
          label=""
          name="checkbox"
          rules={[
            {
              required: false
            }
          ]}
        >
          <Row gutter={[16, 0]}>
            <Col span={1}>
              <Checkbox onChange={toggleCheckbox} allowClear className="mr-2" />{' '}
            </Col>
            <Col span={23}>
              <span style={{ fontWeight: 'lighter' }}>
                I have verified the applicability of the Standard DOA for this
                envelope and would like to proceed with the creation of an
                ad-hoc envelope.
              </span>
            </Col>
          </Row>
        </FormItem> */}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Location"
                name="location"
                rules={[{ required: true, message: 'Select location' }]}
              >
                <Select placeholder="Select location">
                  {/* <Option value="location1">Location 1</Option>
                <Option value="location2">Location 2</Option> */}
                  {locations.map((location) => (
                    <Option key={location.id} value={location.id}>
                      {location.location_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Function"
                name="function"
                rules={[{ required: true, message: 'Select function' }]}
              >
                <Select placeholder="Select function">
                  {/* <Option value="function1">Function 1</Option>
                <Option value="function2">Function 2</Option> */}
                  {functions.map((functions) => (
                    <Option key={functions.id} value={functions.id}>
                      {functions.function_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Category"
                name="category"
                rules={[{ required: true, message: 'Select a Category type' }]}
              >
                <Radio.Group onChange={handleCategoryChange}>
                  {/* <Radio value="Capex">Capex</Radio>
                  <Radio value="P2P">P2P</Radio> */}
                  {category.map((category) => (
                    <Radio key={category.id} value={category.id}>
                      {category.category_name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Sub Category"
                name="sub_category_id"
                rules={[{ required: true, message: 'Select sub-category' }]}
              >
                <Select placeholder="Select sub-category">
                  {/* <Option value="location1">Location 1</Option>
                <Option value="location2">Location 2</Option> */}
                  {subcategory?.map((location) => (
                    <Option key={location.id} value={location.id}>
                      {location.location_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/*  */}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Purchase Type"
                name="purchaseType"
                rules={[
                  { required: true, message: 'Please select a purchase type' }
                ]}
              >
                <Radio.Group>
                  <Radio value="material">Material</Radio>
                  <Radio value="service">Service</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Minimum Value"
                name="min_value"
                rules={[
                  {
                    required: true,
                    message: 'Please enter minimum value'
                  }
                ]}
              >
                <Input placeholder="Enter minimum value" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Maximum Value"
                name="max_value"
                rules={[
                  {
                    required: true,
                    message: 'Please enter maximum value'
                  }
                ]}
              >
                <Input placeholder="Enter maximum value" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Please enter the title' }]}
              >
                <Input placeholder="Enter title" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <FormItem className="text-right">
            <Space>
              <CustomButton htmlType="button" onClick={handleCancel}>
                Cancel
              </CustomButton>
              <CustomButton
                // disabled={!isChecked}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Next
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </CustomModal>
    </>
  );
};

export default AdhocModal;
