import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  approvers,
  create_envelop,
  create_standard_envelop,
  projects,
  customenv,
  allenv,
  unit,
  price,
  categories,
  subcategories,
  get_AllStandardEnvelopes,
  getlocationss,
  get_functions
} from './api';

const initialState = {
  allApprovers: [],
  getAllApproverLoading: false,
  getAllEnvLoading: false,
  projects: [],
  customenv: [],
  departments: [],
  pricebrackets: [],
  categories: [],
  subcategories: [],
  vendors: [],
  envelopeDataLoading: false,
  get_locations: [],
  get_functions: []
};

const actions = {
  GET_ALL_APPROVERS: 'getAllApprovers/GET_ALL_APPROVERS',
  CREATE_ENVELOP: 'createEnvelop/CREATE_ENVELOP',
  CREATE_STANDARD_ENVELOP: 'createStandardEnvelop/CREATE_STANDARD_ENVELOP',
  GET_ALL_PROJECTS: 'getAllprojects/GET_ALL_PROJECTS',
  GET_ALL_CUSTOM_ENV: 'getAllcustomenv/GET_ALL_CUSTOM_ENV',
  GET_ALL_STANDARD_ENV: 'getAllstandardenv/GET_ALL_STANDARD_ENV',
  GET_ALL_ENV: 'getAllenv/GET_ALL_ENV',
  GET_ALL_DEPARTMENTS: 'getAllDepartments/GET_ALL_DEPARTMENTS',
  GET_ALL_PRICE_BRACKETS: 'getAllPricebrackets/GET_ALL_PRICE_BRACKETS',
  GET_ALL_CATEGORIES: 'getAllCategories/GET_ALL_CATEGORIES',
  GET_ALL_SUB_CATEGORIES: 'getAllSubCategories/GET_ALL_SUB_CATEGORIES',
  GET_ALL_VENDORS: 'getAllVendors/GET_ALL_VENDORS',
  GET_LOCATIONS: 'getLocations/GET_LOCATIONS',
  GET_FUNCTIONS: 'getFunctions/GET_FUNCTIONS'
};

export const getAllApprovers = createAsyncThunk(
  actions.GET_ALL_APPROVERS,
  async (payload) => {
    const response = await approvers('list', payload);
    return response;
  }
);

export const createEnvelop = createAsyncThunk(
  actions.CREATE_ENVELOP,
  async (payload) => {
    const response = await create_envelop('add-envelope', payload);
    return response;
  }
);

export const createStandardEnvelop = createAsyncThunk(
  actions.CREATE_STANDARD_ENVELOP,
  async (payload) => {
    const response = await create_standard_envelop(
      'add-standard-envelope',
      payload
    );
    return response;
  }
);

export const getAllprojects = createAsyncThunk(
  actions.GET_ALL_PROJECTS,
  async (payload) => {
    const response = await projects('all-projects', payload);
    return response;
  }
);

export const getAllDepartments = createAsyncThunk(
  actions.GET_ALL_DEPARTMENTS,
  async (payload) => {
    const response = await unit('all', payload);
    return response;
  }
);

export const getAllPricebrackets = createAsyncThunk(
  actions.GET_ALL_PRICE_BRACKETS,
  async (payload) => {
    const response = await price('all', payload);
    return response;
  }
);

export const getAllCategories = createAsyncThunk(
  actions.GET_ALL_CATEGORIES,
  async (payload) => {
    const response = await categories('all', payload);
    return response;
  }
);

export const getAllSubCategories = createAsyncThunk(
  actions.GET_ALL_SUB_CATEGORIES,
  async (payload) => {
    const response = await subcategories('all', payload);
    return response;
  }
);

export const getAllcustomenv = createAsyncThunk(
  actions.GET_ALL_CUSTOM_ENV,
  async (payload) => {
    const response = await customenv(`all-custom`, payload);
    return response;
  }
);

export const getAllstandardenv = createAsyncThunk(
  actions.GET_ALL_STANDARD_ENV,
  async (payload) => {
    const response = await get_AllStandardEnvelopes(`all-standard`, payload);
    return response;
  }
);
export const getAllenvByID = createAsyncThunk(
  actions.GET_ALL_ENV,
  async (payload) => {
    const response = await allenv(`all?id=${payload}`, '');
    return response;
  }
);

export const getAllVendors = createAsyncThunk(
  actions.GET_ALL_VENDORS,
  async (payload) => {
    const response = await allenv(`find-vendor?find_vendor=${payload}`, '');
    return response;
  }
);

export const getLocations = createAsyncThunk(
  actions.GET_LOCATIONS,
  async (payload) => {
    const response = await getlocationss('locations', payload);
    return response;
  }
);

export const getfunctions = createAsyncThunk(
  actions.GET_FUNCTIONS,
  async (payload) => {
    const response = await get_functions('functions', payload);
    return response;
  }
);

export const approversSlice = createSlice({
  name: 'approvers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllApprovers.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllApprovers.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allApprovers = data;
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllApprovers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createEnvelop.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(createEnvelop.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success(msg);
        }
      })
      .addCase(createEnvelop.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createStandardEnvelop.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(createStandardEnvelop.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success(msg);
        }
      })
      .addCase(createStandardEnvelop.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllprojects.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllprojects.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.projects = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllprojects.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllcustomenv.pending, (state) => {
        state.envelopeDataLoading = true;
      })
      .addCase(getAllcustomenv.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.envelopeDataLoading = false;
        state.customenv = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllcustomenv.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.envelopeDataLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllstandardenv.pending, (state) => {
        state.envelopeDataLoading = true;
      })
      .addCase(getAllstandardenv.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.envelopeDataLoading = false;
        state.customenv = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllstandardenv.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.envelopeDataLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllenvByID.pending, (state) => {
        state.getAllEnvLoading = true;
      })
      .addCase(getAllenvByID.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.customenv = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllenvByID.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllDepartments.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllDepartments.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.departments = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllDepartments.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllPricebrackets.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllPricebrackets.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.pricebrackets = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllPricebrackets.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllCategories.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.categories = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllSubCategories.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllSubCategories.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.subcategories = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllSubCategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllVendors.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllVendors.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.vendors = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllVendors.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.get_locations = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getLocations.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getfunctions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getfunctions.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.get_functions = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getfunctions.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});
export default approversSlice.reducer;
