import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createSbu } from '@/features/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Input, Row, message, Select } from 'antd';
//
import React from 'react';
import { useDispatch } from 'react-redux';
const { Option } = Select;

const AddModal = ({
  handleSbgSearchChange,
  all_SBGs,
  onCancel,
  isModalVisible,
  setIsModalOpen,
  handleGetAllApprovers
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    dispatch(createSbu(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsModalOpen(false);
        handleGetAllApprovers();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const activeSBGs = all_SBGs?.filter((item) => item?.is_active);
  return (
    <CustomModal
      footer={
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton
                /* onClick={onOk} */ type="primary"
                htmlType="submit"
              >
                Add
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isModalVisible}
      title="Add User"
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row gutter={24}>
          <Col span={12}>
            <FormItem
              label={'Name'}
              name="name"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Enter name'
                }
              ]}
            >
              <Input placeholder="Enter your name" />
              {/* <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleSbgSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select SBG"
              >
                {activeSBGs?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.sbg_name}
                  </Option>
                ))}
              </Select> */}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={'Email ID'}
              name="email"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Enter your email'
                }
              ]}
            >
              <Input placeholder="Enter SBU Name" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <FormItem
              label={'Designation'}
              name="designation"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Enter your designation'
                }
              ]}
            >
              <Input placeholder="Enter your designation" />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={'Function'}
              name="function"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Select function'
                }
              ]}
            >
              {/* <Input placeholder="Enter SBU Name" /> */}
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleSbgSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select function"
              >
                {activeSBGs?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.sbg_name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <FormItem
              label={'Contact'}
              name="contact"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: false,
                  message: 'Enter your contact number'
                }
              ]}
            >
              <Input placeholder="Enter your contact number" />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={'Location'}
              name="location"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Select location'
                }
              ]}
            >
              {/* <Input placeholder="Enter SBU Name" /> */}
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleSbgSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select location"
              >
                {activeSBGs?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.sbg_name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default AddModal;
