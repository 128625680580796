import React, { useEffect, useState } from 'react';
import ReviewActionsPage from './components/viewFile';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getfilebyid,
  // getOtp,
  update_envelope,
  getRejectedApprovers,
  cancel_envelope
} from './redux/slice';
import { getLoggedInUser } from 'utilities/helpers';
import { history } from 'app/history';
import { getAllApprovers } from '../initiate-envelope/redux/slice';

function ReviewActions() {
  // , otp, rejected_approvers, allFileLoading
  const { all_file_data } = useSelector((state) => state.reviewActions);
  // console.log('all_file_data', all_file_data);
  const { allApprovers } = useSelector((state) => state.approvers);
  const [fileList, setFileList] = useState([]);
  const [modalVisible, setModalVisible] = useState('');
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const user = getLoggedInUser();

  useEffect(() => {
    handleGetfileById();
    handleGetAllApprovers();
  }, []);

  const handleGetAllApprovers = () => {
    dispatch(getAllApprovers());
  };

  const handleGetfileById = () => {
    dispatch(getfilebyid(params.id));
  };

  const handleSetReject = (type) => {
    let payload = {
      envelope_id: params.id
    };
    dispatch(getRejectedApprovers(payload));
    setModalVisible(type);
  };

  // const sentotp = () => {
  //   const paylaod = { email: user.user_details.email, envelope_id: params.id };
  //   dispatch(getOtp(paylaod));
  //   setModalVisible('approved');
  // };

  const onFinish = (values) => {
    let formData = new FormData();

    // values['email'] = user.user_details.email;
    // values['env_id'] = params.id;
    // values['status'] =
    //   modalVisible === 'approved'
    //     ? 'Accepted'
    //     : modalVisible === 'reject'
    //     ? 'Rejected'
    //     : modalVisible === 'forward'
    //     ? 'Forwarded'
    //     : modalVisible === 'forward-comments'
    //     ? 'Forwarded-envelope-reviewer'
    //     : modalVisible === 'rejected-comments'
    //     ? 'Rejected-envelope-reviewer'
    //     : 'Hold';

    formData.append('email', user.user_details.email);
    formData.append('reviewer', values.reviewer);
    formData.append('comments', values.comments);
    modalVisible === 'approved' ? formData.append('sign', values.sign) : null;
    formData.append('env_id', params.id);
    formData.append(
      'status',
      modalVisible === 'approved'
        ? 'Accepted'
        : modalVisible === 'reject'
        ? 'Rejected'
        : modalVisible === 'forward'
        ? 'Forwarded'
        : modalVisible === 'forward-comments'
        ? 'Forwarded-envelope-reviewer'
        : modalVisible === 'rejected-comments'
        ? 'Rejected-envelope-reviewer'
        : 'Hold'
    );
    for (let i = 0; i < fileList.length; i++) {
      formData.append(`file`, fileList[i].originFileObj);
    }

    dispatch(update_envelope(formData)).then((response) => {
      if (response.payload.success) {
        history.push('/actions-required');
      }
    });
    setModalVisible('');
    setFileList([]);
  };

  const onFinishCancel = (values) => {
    values['envelope_id'] = params.id;
    values['status'] = 'Cancelled';
    dispatch(cancel_envelope(values)).then((response) => {
      if (response.payload.success) {
        history.push('/cancelled');
      }
    });
  };

  return (
    <>
      <ReviewActionsPage
        // allFileLoading={allFileLoading}
        fileList={fileList}
        setFileList={setFileList}
        allApprovers={allApprovers}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        all_file_data={all_file_data}
        // sentotp={sentotp}
        // otp={otp}
        onFinish={onFinish}
        handleSetReject={handleSetReject}
        // rejected_approvers={rejected_approvers}
        setEditMode={setEditMode}
        editMode={editMode}
        onFinishCancel={onFinishCancel}
      />
    </>
  );
}

export default ReviewActions;
