import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '@/common/Layout';
import DashBoardPage from './components/DashBoardPage';
import ADHOC from './images/ADHOC.svg';
import Standard from './images/Standard.svg';
import Custom from './images/Custom.svg';
import {
  // getAllStats,
  // getAllSubCategories,
  // getAllPricebrackets,
  // getAllCategories,
  getdoa,
  // getAllProjects,
  // getAllBUs,
  getLocations,
  getdashboardCount,
  getfunctions,
  getcategory
} from './redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
// import { history } from 'app/history';
// import { message } from 'antd';

const envelopeTypes = [
  {
    title: 'Initiate ad-hoc envelope',
    subText:
      'Best suited for documents that do not have a defined division of authority process',
    btnText: 'Initiate ad-hoc envelope',
    image: ADHOC,
    key: 1
  },
  {
    title: 'Initiate standard envelope',
    subText:
      'Choose this for confidential documents or agreements with significant material impact',
    btnText: 'Initiate standard DoA envelope',
    image: Standard,
    key: 2
  },
  {
    title: 'Initiate custom DoA envelope',
    subText:
      'Select this to send documents without a standard format along a preconfigured flow',
    btnText: 'Initiate custom DoA envelope',
    image: Custom,
    key: 3
  }
];

const DashBoard = () => {
  const [form] = Form.useForm();
  // allStats, pricebrackets, categories, subcategories, projects, bu
  const {
    get_locations,
    dashboard_count,
    get_functions,
    get_category,
    get_subcategory
  } = useSelector((state) => state.dashboard);

  const dispatch = useDispatch();
  // useEffect(() => {
  //   handleGetStats();
  // }, []);

  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isStandardModalVisible, setIsStandardModalVisible] = useState(false);
  const [isCustomModalVisible, setIsCustomModalVisible] = useState(false);
  const [isStandardTemplateModalVisible, setIsStandardTemplateModalVisible] =
    useState(false);
  const [standardValues, setStandardValues] = useState([]);
  // const [selectedProject, setSelectedProject] = useState([]);
  // const [searchproject, setSearchproject] = useState('');
  // const [searchCategory, setSearchCategory] = useState('');
  // const [searchSubCategory, setSearchSubCategory] = useState('');
  // const [selectedBU, setSelectedBU] = useState([]);
  // const [searchbu, setSearchbu] = useState('');
  // const [categoryId, setCategoryId] = useState('');
  // const [showProject, setShowProject] = useState(false);
  // const [category, setCategory] = useState(categories);
  const [type, setType] = useState('');
  const [payloadDataForAllStandard, setPayloadDataForAllStandard] = useState(
    {}
  );
  // const [locations, setLocations] = useState([]);

  const onFinish = (values) => {
    // localStorage.setItem('envelope_name', values.envelope_name);
    // localStorage.setItem('description', values.description);

    localStorage.setItem('location', values.location);
    localStorage.setItem('function', values.function);
    localStorage.setItem('budgetType', values.budgetType);
    localStorage.setItem('purchaseType', values.purchaseType);
    localStorage.setItem('value', values.value);
    localStorage.setItem('title', values.title);
    navigate('/initiate-envelope-1');
    form.resetFields();
  };

  // const handleGetStats = () => {
  // dispatch(getAllStats());
  // dispatch(getAllPricebrackets());
  // dispatch(getLocations());
  // };

  // useEffect(() => {
  //   const payload = { search: searchCategory };
  //   dispatch(getAllCategories(payload));
  // }, [searchCategory]);

  // useEffect(() => {
  //   handleGetAllProjects();
  //   handlegetLocations();
  //   dashboardCount();
  // }, [searchproject]);

  // const handleGetAllProjects = () => {
  //   dispatch(getAllProjects(searchproject));
  // };

  useEffect(() => {
    handlegetLocations();
    dashboardCount();
    handlegetfunctions();
    handlegetcategory();
  }, []);
  const dashboardCount = () => {
    dispatch(getdashboardCount());
    dispatch(getLocations());
    dispatch(getfunctions());
    dispatch(getcategory());
  };

  const handlegetLocations = () => {
    dispatch(getLocations());
  };

  const handlegetfunctions = () => {
    dispatch(getfunctions());
  };

  const handlegetcategory = () => {
    dispatch(getcategory());
  };

  // useEffect(() => {
  //   handleGetAllBU();
  // }, [searchbu]);

  // const handleGetAllBU = () => {
  //   dispatch(getAllBUs(searchbu));
  // };

  // console.log('getLocations', get_locations);
  // console.log('dashboard_count', dashboard_count);

  const showModal = () => {
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const showStandardModal = (value) => {
    setIsStandardModalVisible(true);
    // console.log('value', value);
    setType(value === 1 ? 'standard' : 'custom');
    form.resetFields();
  };

  const handleStandardCancel = () => {
    setIsStandardTemplateModalVisible(false);
    setIsStandardModalVisible(false);
    form.resetFields();
  };

  const handleStandardtemplateCancel = () => {
    setIsStandardTemplateModalVisible(false);
    form.resetFields();
  };

  const showCustomdModal = () => {
    setIsCustomModalVisible(true);
    form.resetFields();
  };

  const handleCustomCancel = () => {
    setIsCustomModalVisible(false);
    form.resetFields();
  };

  // const handleCategory = (e) => {
  //   const payload = {
  //     category_id: e,
  //     sub_search: searchSubCategory
  //   };
  //   var filter = categories.filter((categories) => categories.id === e);

  //   filter[0].is_active_project ? setShowProject(true) : setShowProject(false);
  //   dispatch(getAllSubCategories(payload));
  // };

  // useEffect(() => {
  //   const payload = {
  //     category_id: categoryId,
  //     sub_search: searchSubCategory
  //   };
  //   dispatch(getAllSubCategories(payload));
  // }, [searchSubCategory]);

  // const handleSubCategory = (e) => {
  //   var filter = subcategories.filter(
  //     (subcategories) => subcategories.id === e
  //   );
  //   filter[0].is_active_project ? setShowProject(true) : setShowProject(false);
  // };

  // const onSelectProject = (data) => {
  //   setSelectedProject([...selectedProject, { project: data.project }]);
  // };

  // const onSearch = (value) => {
  //   setSearchproject(value);
  // };

  // const onSearchCategory = (value) => {
  //   setSearchCategory(value);
  // };

  // const onSearchSubCategory = (value) => {
  //   setSearchSubCategory(value);
  // };
  // const onSelectBU = (data) => {
  //   setSelectedBU([...selectedBU, { bu: data.bu }]);
  // };

  // const onSearchbu = (value) => {
  //   setSearchbu(value);
  // };

  const handleSearchstandard = (payload) => {
    setPayloadDataForAllStandard(payload);
    dispatch(getdoa(payload)).then((response) => {
      response.payload.data
        ? (setStandardValues(response.payload.data),
          setIsStandardTemplateModalVisible(true),
          setIsStandardModalVisible(false))
        : // ? history.push(
          //     '/initiate-envelope-1/' +
          //       `${response.payload.data.id}?type=${payload.type}`
          //   )
          // : message.error(response.payload.message);
          (setIsStandardTemplateModalVisible(true),
          setIsStandardModalVisible(false));
    });
  };
  // console.log('setPayloadDataForAllStandard', setPayloadDataForAllStandard);
  const stats = [
    {
      key: 1,
      label: 'Actions required',
      value: dashboard_count?.action_required || 0,
      link: '/actions-required'
    },
    {
      key: 2,
      label: 'Waiting for others',
      value: dashboard_count?.waiting_for_others || 0,
      link: '/pending'
    },
    {
      key: 3,
      label: 'Need Clarification',
      value: dashboard_count?.need_clarification || 0,
      link: '/hold'
    },
    {
      key: 4,
      label: 'Completed',
      value: dashboard_count?.completed || 0,
      link: '/approvers'
    }
  ];

  return (
    <>
      <Layout sider={false}>
        <DashBoardPage
          // setSearchSubCategory={setSearchSubCategory}
          // setCategoryId={setCategoryId}
          // onSearchSubCategory={onSearchSubCategory}
          form={form}
          // onSearchCategory={onSearchCategory}
          payloadDataForAllStandard={payloadDataForAllStandard}
          stats={stats}
          envelopeTypes={envelopeTypes}
          isModalVisible={isModalVisible}
          isStandardModalVisible={isStandardModalVisible}
          isCustomModalVisible={isCustomModalVisible}
          onFinish={onFinish}
          showModal={showModal}
          showCustomdModal={showCustomdModal}
          showStandardModal={showStandardModal}
          handleCancel={handleCancel}
          handleCustomCancel={handleCustomCancel}
          handleStandardCancel={handleStandardCancel}
          // allStats={allStats}
          setIsCustomModalVisible={setIsCustomModalVisible}
          // pricebrackets={pricebrackets}
          // categories={categories}
          // handleCategory={handleCategory}
          // subcategories={subcategories}
          handleSearchstandard={handleSearchstandard}
          isStandardTemplateModalVisible={isStandardTemplateModalVisible}
          setIsStandardTemplateModalVisible={setIsStandardTemplateModalVisible}
          standardValues={standardValues}
          setStandardValues={setStandardValues}
          handleStandardtemplateCancel={handleStandardtemplateCancel}
          // onSelectProject={onSelectProject}
          // onSearch={onSearch}
          // projects={projects}
          // onSelectBU={onSelectBU}
          // onSearchbu={onSearchbu}
          // bu={bu}
          // showProject={showProject}
          // handleSubCategory={handleSubCategory}
          // setCategory={setCategory}
          // category={category}
          locations={get_locations}
          dashboardcount={dashboardCount}
          type={type}
          functions={get_functions}
          category={get_category}
          subcategory={get_subcategory}
        />
      </Layout>
    </>
  );
};

export default DashBoard;
