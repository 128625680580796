import apiClient from '../../../utilities/apiClient';

export const allTrackers = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.filtercount}`, payload, true);
};

export const allData = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dataList}`, payload, true);
};

export const history = (url) => {
  return apiClient.get(`${apiClient.Urls.admin_tracker}/${url}`, '', true);
};

export const resend = (url) => {
  return apiClient.get(`${apiClient.Urls.admin_tracker}/${url}`, '', true);
};
