import { Layout } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './components/InitiateEnvelopePage1/index.module.less';
import InititateEnvelopePage1 from './components/InitiateEnvelopePage1';
import InititateEnvelopePage2 from './components/InitiateEnvelopePage2';
import {
  getAllApprovers,
  createEnvelop,
  createStandardEnvelop,
  // getAllprojects,
  // getAllcustomenv,
  getAllenvByID,
  // getAllDepartments,
  // getAllCategories,
  // getAllSubCategories,
  // getAllVendors,
  getAllstandardenv,
  getLocations,
  getfunctions
} from './redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'app/history';
import { useParams, useLocation } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';

const InitiateEnvelope = ({
  tempfileList,
  setInitiateEnv,
  setTempFileList
}) => {
  const {
    // getAllApproverLoading,
    allApprovers,
    // projects,
    customenv,
    // departments,
    // pricebrackets,
    // categories,
    // subcategories,
    // vendors,
    // envelopeDataLoading,
    get_locations,
    get_functions
  } = useSelector((state) => state.approvers);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [approverSearch, setApproverSearch] = useState('');
  const [itemList, setItemList] = useState([{ id: 1, value: '', envtype: '' }]);
  const [type, setType] = useState(false);
  const [envtype, setEnvtype] = useState(false);
  const [fields, setFields] = useState([{ value: null }]);
  const [current, setCurrent] = useState(0);
  const [mergedPdfBlob, setMergedPdfBlob] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [initiate, setInitiate] = useState(false);
  const [emailmessage, setEmailmessage] = useState('');
  const [projectId, setProjectId] = useState(false);
  const [signOrder, setSignOrder] = useState(true);
  const [noti, setNoti] = useState();
  const [formatModal, setFormatModal] = useState(false);
  const [select, setSelect] = useState('');
  // const [category, setCategory] = useState('');
  const [formValues, setformValues] = useState({});
  const [searchproject, setSearchproject] = useState('');
  const [selectedProject, setSelectedProject] = useState([]);
  // const [searchbu, setSearchbu] = useState('');
  // const [vendorName, setVendorName] = useState();
  // const [cust, setCust] = useState([]);
  // const [subSearch, setSubSearch] = useState('');

  // const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  // const [categorySearch, setCategorySearch] = useState('');

  // const handleGetCategories = () => {
  //   const payload = categorySearch != '' ? { search: categorySearch } : {};
  //   dispatch(getAllCategories(payload));
  // };

  // const handleGetSubCategories = (id) => {
  //   const payload = {
  //     sub_search: subSearch !== '' ? subSearch : '',
  //     category_id: id
  //   };

  //   dispatch(getAllSubCategories(payload));
  // };

  // useEffect(() => {
  //   if (selectedCategoryId !== null && selectedCategoryId !== undefined) {
  //     handleGetSubCategories(selectedCategoryId); // Fetch subcategories when
  //   } // subSearch changes.
  // }, [subSearch, selectedCategoryId]);

  // useEffect(() => {
  //   handleGetCategories();
  // }, [categorySearch]);

  const params = useParams();
  const location = useLocation();
  const env_type = new URLSearchParams(location.search).get('type');
  const env_mode = new URLSearchParams(location.search).get('mode');
  useEffect(() => {
    //   if (env_type === 'custom' && env_type !== null) {
    //     setSignOrder(true);
    // handleGetCustomEnv();
    //   }
    if (env_type === 'standard' && env_type !== null) {
      setSignOrder(true);
      handleGetStandardEnv();
    }
    if (env_mode === 'edit' && env_mode !== null) {
      setSignOrder(true);
      handleGetEnv();
    }
  }, []);

  useEffect(() => {
    handlegetLocations();
    handlegetfunctions();
  }, []);

  const handlegetLocations = () => {
    dispatch(getLocations());
  };

  const handlegetfunctions = () => {
    dispatch(getfunctions());
  };

  // useEffect(() => {
  //   const payload = { search: searchbu };
  //   dispatch(getAllDepartments(payload));
  // }, [searchbu]);

  // useEffect(() => {
  //   handleGetAllProjects();
  // }, [searchproject]);

  // useEffect(() => {
  //   dispatch(getAllVendors(vendorName));
  // }, [vendorName]);

  const approverPayload = useMemo(() => {
    return { type: 'approvers', search: approverSearch };
  }, [approverSearch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(getAllApprovers(approverPayload));
    }, 500);

    // Cleanup the timeout to avoid unnecessary dispatch if the component unmounts or the dependency changes
    return () => clearTimeout(timeoutId);
  }, [dispatch, approverPayload]);

  // const handleCategory = (e) => {
  //   const payload = {
  //     sub_search: subSearch,
  //     category_id: e
  //   };
  //   dispatch(getAllSubCategories(payload));
  // };

  // useEffect(() => {
  //   handleCategory(selectedCategoryId);
  // }, [subSearch, selectedCategoryId]);

  // const handleCategorySearch = (e) => {
  //   setCategorySearch(e);
  // };

  // const handleSubCategorySearch = (e) => {
  //   setSubSearch(e);
  // };

  // const handleGetCustomEnv = () => {
  //   const payload = {
  // id: params.id,
  // unit_id: apiCallVal.unit_id,
  // category_id: apiCallVal.category_id,
  // sub_category_id: apiCallVal.sub_category_id,
  // project_id: apiCallVal.project_id
  //   };
  //   dispatch(getAllcustomenv(payload)).then((response) => {
  //     setCust(response.payload.data);
  //   });
  // };
  // const handleGetAllProjects = () => {
  //   const payload = {
  //     search: searchproject
  //   };
  //   dispatch(getAllprojects(payload));
  // };

  const apiCallVal = LocalStorage.getItem('apiCallValues');

  const handleGetStandardEnv = () => {
    const payload = {
      id: params.id,
      unit_id: apiCallVal.unit_id,
      category_id: apiCallVal.category_id,
      sub_category_id: apiCallVal.sub_category_id,
      project_id: apiCallVal.project_id
    };
    dispatch(getAllstandardenv(payload));
  };

  const handleGetEnv = () => {
    dispatch(getAllenvByID(params.id));
  };

  useEffect(() => {
    if (
      env_type === 'custom' ||
      env_type === 'standard' ||
      env_mode === 'edit'
    ) {
      if (customenv?.approvers) {
        let selectedOption = [];
        let itemList = [];
        customenv?.approvers.forEach((item) => {
          selectedOption = [
            ...selectedOption,
            {
              name: item.display_name,
              email: item.email,
              job_title: item?.job_title
            }
          ];
          itemList = [
            ...itemList,
            { id: item.id, value: item.email, envtype: item.id }
          ];
        });
        setSelectedOption(selectedOption);
        setItemList(itemList);
      }
    }
  }, [customenv]);
  // console.log('onSelect', onSelect);
  // const handleOptionSelect = (value) => {
  //   setSelectedOption(value);
  //   console.log('Selected option:', value);
  // };

  const handleFormSubmit = () => {
    setInitiate(true);
    let updatd = [];
    console.log('selectedOption', selectedOption);
    selectedOption.map((item, index) => {
      var obj = {};
      // obj['name'] = item.display_name;
      // obj['email'] = item.email;
      obj['approver_id'] = item.id;
      signOrder ? (obj['sequence'] = index + 0) : '';
      // obj['sign_in'] = index === 0 ? true : false;
      updatd.push(obj);
    });
    // console.log('fileList in Parent', mergedPdfBlob);
    let formData = new FormData();
    /****** Blob to file converter */
    // const blob = new Blob([mergedPdfBlob], {
    //   type: 'application/pdf; '
    // });

    // blob.name = `${localStorage.getItem('envelope_name')}.pdf`;
    // blob instanceof File;
    // const myFile = new File(
    //   [blob],
    //   `${localStorage.getItem('envelope_name')}.pdf`,
    //   {
    //     type: blob.type
    //   }
    // );
    /************** End */

    fileList.forEach((file) => {
      formData.append(`documents`, file);
    });
    formData.append('type', env_type ? env_type : 'ad-hoc');
    // formData.append('documents', mergedPdfBlob);
    // formData.append('documents', fileList[0]);
    formData.append('title', formValues?.title);
    formData.append('location_id', formValues?.location_id);
    formData.append('function_id', formValues?.function_id);
    formData.append('approvers', JSON.stringify(updatd));
    formData.append('budget_type', formValues?.budget_type);
    formData.append('purchase_type', formValues?.purchase_type);
    formData.append('value', formValues?.value);
    formData.append('description', formValues?.description);
    formData.append('message', formValues?.message);

    // formValues?.project_code !== undefined
    //   ? formData.append('project_id', formValues?.project_code)
    //   : '';
    // formData.append('category_id', formValues?.Category);
    // formData.append('sub_category_id', formValues.sub_category_id);
    // formData.append('price_bracket_id', formValues?.price_bracket_id);
    // formData.append('min_price', formValues?.min_price);
    // formData.append('max_price', formValues?.max_price);
    // formData.append('unit_id', formValues?.unit_id);
    // formData.append('purchase_work_order', formValues?.purchase_work_order);
    // formData.append('purchase_requistion', formValues?.purchase_requistion);
    // formData.append('budget_availablity', formValues?.budget_availablity);
    // formData.append('main_group', formValues?.main_group);
    // formData.append('minor_group', formValues?.minor_group);
    // formData.append('vendor_name', formValues?.vendor_name);
    // formData.append('vendor_erp_code', formValues?.vendor_erp_code);
    // formData.append('total_value', formValues?.total_value);
    dispatch(createEnvelop(formData)).then((response) => {
      if (response.payload.success) {
        setInitiate(false);
        setSignOrder(false);
        history.push('/dashboard');
      } else {
        setInitiate(false);
      }
    });
  };

  // const handlegetVendors = (data) => {

  // }
  const handleSave = () => {
    let updatd = [];
    selectedOption.map((item, index) => {
      var obj = {};
      // obj['name'] = item.display_name;
      // obj['email'] = item.email;
      obj['approver_id'] = item.id;
      obj['sequence'] = index;
      // obj['sign_in'] = index === 0 ? true : false;
      updatd.push(obj);
    });

    let formData = new FormData();
    formData.append('title', localStorage.getItem('doa_name'));
    formData.append('description', localStorage.getItem('doa_description'));
    formData.append('project_id', localStorage.getItem('project_id'));
    formData.append('approvers', JSON.stringify(updatd));
    formData.append('envelope_type', localStorage.getItem('envelope_type'));
    formData.append('message', emailmessage);

    for (let i = 0; i < tempfileList.length; i++) {
      formData.append(`file`, tempfileList[i].originFileObj);
    }
    dispatch(createStandardEnvelop(formData)).then((response) => {
      if (response.payload.success) {
        setSignOrder(false);
        setInitiateEnv(false);
        setTempFileList([]);
        history.push('/admin-dashboard');
      } else {
        setInitiate(false);
      }
    });
  };
  // console.log('setformValues', formValues);
  return (
    <>
      <Layout className={`${styles.layout}`}>
        <>
          {current == 0 ? (
            <div className="steps-content">
              <InititateEnvelopePage1
                // envelopeDataLoading={envelopeDataLoading}
                setApproverSearch={setApproverSearch}
                // setSelectedCategoryId={setSelectedCategoryId}
                // handleGetSubCategories={handleGetSubCategories}
                // handleCategorySearch={handleCategorySearch}
                // handleSubCategorySearch={handleSubCategorySearch}
                // setCategorySearch={setCategorySearch}
                // setSubSearch={setSubSearch}
                noti={noti}
                setNoti={setNoti}
                select={select}
                setSelect={setSelect}
                setFileList={setFileList}
                fileList={fileList}
                itemList={itemList}
                setItemList={setItemList}
                type={type}
                setType={setType}
                envtype={envtype}
                setEnvtype={setEnvtype}
                current={current}
                setCurrent={setCurrent}
                fields={fields}
                setFields={setFields}
                setMergedPdfBlob={setMergedPdfBlob}
                // getAllApproverLoading={getAllApproverLoading}
                allApprovers={allApprovers}
                options={options}
                setOptions={setOptions}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                emailmessage={emailmessage}
                setEmailmessage={setEmailmessage}
                handleSave={handleSave}
                // projects={projects}
                projectId={projectId}
                setProjectId={setProjectId}
                setSignOrder={setSignOrder}
                signOrder={signOrder}
                // customenv={
                //   env_type === 'custom' || env_type === 'standard'
                //     ? customenv
                //     : ''
                // }
                // env_type={env_type}
                formatModal={formatModal}
                setFormatModal={setFormatModal}
                // departments={departments}
                // pricebrackets={pricebrackets}
                // categories={categories}
                // category={category}
                // setCategory={setCategory}
                // handleCategory={handleCategory}
                // subcategories={subcategories}
                formValues={formValues}
                setformValues={setformValues}
                searchproject={searchproject}
                setSearchproject={setSearchproject}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                // handlegetVendors={handlegetVendors}
                // setVendorName={setVendorName}
                // vendors={vendors}
                // cust={cust}
                // setSearchbu={setSearchbu}
                // vendors={vendors}
                // setVendors={setVendors}
                locations={get_locations}
                functions={get_functions}
              />
            </div>
          ) : (
            <div className="steps-content">
              <InititateEnvelopePage2
                setFileList={setFileList}
                fileList={fileList}
                itemList={itemList}
                setItemList={setItemList}
                type={type}
                setType={setType}
                envtype={envtype}
                setEnvtype={setEnvtype}
                current={current}
                setCurrent={setCurrent}
                mergedPdfBlob={mergedPdfBlob}
                selectedOption={selectedOption}
                handleFormSubmit={handleFormSubmit}
                initiate={initiate}
                setInitiate={setInitiate}
                emailmessage={emailmessage}
                // projects={projects}
                projectId={projectId}
                setProjectId={setProjectId}
                setSignOrder={setSignOrder}
                customenv={
                  env_type === 'custom' || env_type === 'standard'
                    ? customenv
                    : ''
                }
                locations={get_locations}
                functions={get_functions}
              />
            </div>
          )}
        </>
      </Layout>
    </>
  );
};

export default InitiateEnvelope;
