import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Breadcrumb, Button, Col, Row } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import CustomLayout from '@/common/Layout/index';
// import emptyState from '../../../admin-doa-envelope/images/empty.svg';
import SearchBar from '@/common/Content/searchBar';
// import CustomTable from '@/common/Content/CustomTable';
import { useDispatch } from 'react-redux';
import { getAllSbgs, getAllSbus } from '../../redux/slice';
// getSbuById
// import Pencil from '@/features/initiate-envelope/images/Pencilsvg';
import AddModal from './AddModal';
import EditModal from './EditModal';

const Usermanagement = () => {
  //   const { all_SBUs, sbuById, sbu_pagination, all_SBGs } = useSelector(
  //     (state) => state.settings
  //   );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [sbgSearch, setSbgSearch] = useState('');
  //   const [page, setPage] = useState(1);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //   const showEditModal = (id) => {
  //     const payload = { id: id };
  //     dispatch(getSbuById(payload));

  //     setIsEditModalOpen(true);
  //   };
  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const dispatch = useDispatch();

  const handleGetAllApprovers = () => {
    let payload = search !== '' ? { search: search } : { search: search };
    //   , page: page
    dispatch(getAllSbus(payload));
  };

  const handleGetAllSBGs = () => {
    let payload = sbgSearch !== '' ? { search: sbgSearch } : {};
    dispatch(getAllSbgs(payload));
  };

  useEffect(() => {
    handleGetAllSBGs();
  }, [sbgSearch]);

  useEffect(() => {
    handleGetAllApprovers();
  }, [search]);
  //   , page

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSbgSearchChange = (e) => {
    setSbgSearch(e);
  };
  //   const data = all_SBUs?.map((item) => {
  //     const { id, sbu_name, is_active } = item;
  //     return {
  //       ...item,
  //       status: {
  //         is_active
  //       },
  //       approvers: {
  //         sbu_name
  //       },

  //       id: { id }
  //     };
  //   });

  //   const columns = [
  //     {
  //       title: 'Name',
  //       dataIndex: 'approvers',
  //       key: 'approvers',
  //       render: (approvers) => <>{approvers?.sbu_name}</>
  //     },
  //     {
  //       title: 'Status',
  //       dataIndex: 'status',
  //       key: 'status',
  //       render: (status) => (
  //         <Tag color={status?.is_active === true ? 'green' : 'grey'}>
  //           {status?.is_active === true ? 'Active' : 'Inactive'}
  //         </Tag>
  //       )
  //     },
  //     {
  //       title: 'Action',
  //       dataIndex: 'id',
  //       key: 'id',
  //       render: (id) => (
  //         <Tooltip title="Edit">
  //           <span
  //             className="cursor-pointer"
  //             onClick={() => {
  //               showEditModal(id.id);
  //             }}
  //           >
  //             <Pencil />
  //           </span>
  //         </Tooltip>
  //       )
  //     }
  //   ];

  return (
    <CustomLayout sider={false}>
      {' '}
      <div className={`${styles.breadcrub_container}`}>
        {' '}
        <Row>
          <Col span={19}>
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/usermanagement"
              >
                {/* Configure SBUs */}
                User Management
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-right" span={4}>
            <Button
              onClick={showModal}
              className="font-14 sfprotext-bold "
              type="primary"
            >
              Add User
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={18}>
          <div className="mt-3 font-16 sfprotext-bold">User</div>
        </Col>
        <Col span={6}>
          <SearchBar
            onChange={handleSearchChange}
            className={`mt-3 ${styles.createdSearch}`}
            inputProps={{
              placeholder: 'Search by User Name'
            }}
          />
        </Col>
      </Row>
      {/* {data?.length === undefined ? (
        <>
          <div className="df-jc-ac mt-5">
            <img src={emptyState} />
          </div>
          <div className="df-jc-ac mt-3">
            <div className="sfprotext-regular font-14">
              You haven’t created any flows yet.
            </div>
          </div>
          <div className="df-jc-ac mt-3">
            <Button
              onClick={showModal}
              type="primary"
              className="sfprotext-bold font-14"
            >
              Add SBU
            </Button>
          </div>
        </>
      ) : (
        <CustomTable
          rowKey={(id) => id.id.id}
          className={`mt-4 ${styles.custom_table}`}
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            pageSize: 10,
            total: sbu_pagination?.total_records,
            showSizeChanger: false
          }}
          dataSource={data}
          columns={columns}
        />
      )} */}
      {isModalOpen ? (
        <AddModal
          handleSbgSearchChange={handleSbgSearchChange}
          //   all_SBGs={all_SBGs}
          handleGetAllApprovers={handleGetAllApprovers}
          setIsModalOpen={setIsModalOpen}
          isModalVisible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      ) : null}
      {isEditModalOpen ? (
        <EditModal
          handleSbgSearchChange={handleSbgSearchChange}
          //   all_SBGs={all_SBGs}
          handleGetAllApprovers={handleGetAllApprovers}
          id={id}
          setId={setId}
          //   sbuById={sbuById?.[0]}
          setIsEditModalOpen={setIsEditModalOpen}
          isEditModalVisible={isEditModalOpen}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
        />
      ) : null}
    </CustomLayout>
  );
};

export default Usermanagement;
