import apiClient from 'utilities/apiClient';

// export const stats = (url, payload) => {
//   return apiClient.get(
//     `${apiClient.Urls.create_envelop}/${url}`,
//     payload,
//     true
//   );
// };

export const get_AllStandardEnvelopes = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_standard_envelopes}/${url}`,
    payload,
    true
  );
};

export const get_AllCustomEnvelopes = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_standard_envelopes}/${url}`,
    payload,
    true
  );
};

// export const subcategories = (url, payload) => {
//   return apiClient.get(`${apiClient.Urls.subcategories}/${url}`, payload, true);
// };

// export const price = (url, payload) => {
//   return apiClient.get(`${apiClient.Urls.price}/${url}`, payload, true);
// };

// export const categories = (url, payload) => {
//   return apiClient.get(`${apiClient.Urls.categories}/${url}`, payload, true);
// };

export const standardDoa = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_actions_required}/${url}`,
    payload,
    true
  );
};

// export const projects = (url, payload) => {
//   return apiClient.get(`${apiClient.Urls.admin}/${url}`, payload, true);
// };

// export const businessunits = (url, payload) => {
//   return apiClient.get(`${apiClient.Urls.unit}/${url}`, payload, true);
// };

export const getlocationss = (payload) => {
  return apiClient.get(`${apiClient.Urls.locations}`, payload, true);
};

export const dashboard_count = (payload) => {
  return apiClient.get(`${apiClient.Urls.dashboardCount}`, payload, true);
};

export const get_functions = (payload) => {
  return apiClient.get(`${apiClient.Urls.functions}`, payload, true);
};

export const get_category = (payload) => {
  return apiClient.get(`${apiClient.Urls.categorylist}`, payload, true);
};

export const get_subcategory = (url, payload) => {
  // console.log('payload', url);
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
