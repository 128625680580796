import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createSubCategory } from '@/features/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Input, Row, Select, message } from 'antd';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const AddModal = ({
  categories,
  onCancel,
  isModalVisible,
  setIsModalOpen,
  handleGetSubCategories,
  handleCategorySearchChange
}) => {
  const dispatch = useDispatch();
  const [isCategoryActive, setIsCategoryActive] = useState(true);

  // Update the handleSelect function to set the isCategoryActive state
  const handleSelect = (e) => {
    const selectedCategory = categories.find((item) => item.id === e);
    setIsCategoryActive(selectedCategory?.is_active);
    // Rest of your handleSelect logic...
  };
  const [form] = Form.useForm();
  const onFinish = (values) => {
    dispatch(createSubCategory(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsModalOpen(false);
        handleGetSubCategories();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  // const specificity = [
  //   {
  //     value: 'project_specific',
  //     name: 'Project'
  //   },
  //   {
  //     value: 'bu_specific',
  //     name: 'BU'
  //   },
  //   {
  //     value: 'sbu_specific',
  //     name: 'SBU'
  //   },
  //   {
  //     value: 'sbg_specific',
  //     name: 'SBG'
  //   },
  //   {
  //     value: 'corporate',
  //     name: 'Corporate'
  //   }
  // ];

  return (
    <CustomModal
      footer={
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton
                /* onClick={onOk} */ type="primary"
                htmlType="submit"
                disabled={!isCategoryActive}
              >
                Add
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isModalVisible}
      title="Add Sub-Category"
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row>
          {' '}
          <Col span={24}>
            <FormItem
              label={'Category'}
              name="category_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select'
                }
              ]}
            >
              <Select
                suffixIcon={<CaretDownFilled />}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={(e, opt) => handleSelect(e, opt)}
                onSearch={(e) => handleCategorySearchChange(e)}
                placeholder="Select Category"
              >
                {categories?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.is_active === false
                      ? `${item.name} (Deactivated)`
                      : item.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Sub-Category Name'}
              name="name"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter'
                }
              ]}
            >
              <Input placeholder="Enter Sub-Category Name" />
            </FormItem>
          </Col>
          {/* <Col span={24}>
            <FormItem
              label={'Specificity'}
              name="sub_specificity"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select'
                }
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                suffixIcon={<CaretDownFilled />}
                placeholder="Select Specificity"
              >
                {specificity?.map((item, idx) => (
                  <Select.Option key={idx} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col> */}
        </Row>
      </Form>
    </CustomModal>
  );
};

export default AddModal;
