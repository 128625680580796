import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { allTrackers, allData, history, resend } from './api';

const initialState = {
  all_trackers: [],
  all_data: [],
  history_data: [],
  pagination: null,
  loading: false
};

const actions = {
  GETTRACKERSLIST: 'getTrackersList/GETTRACKERSLIST',
  GETALLDATA: 'getAllData/GETALLDATA',
  GET_HISTORY: 'getHistoryList/GET_HISTORY',
  RESEND_ENVELOPE: 'resendEnvelope/RESEND_ENVELOPE',
  EXPORT_CSV: 'exportcsv/EXPORT_CSV'
};

export const getTrackersList = createAsyncThunk(
  actions.GETTRACKERSLIST,
  async (payload) => {
    const response = await allTrackers('all', payload);
    return response;
  }
);

export const getAllData = createAsyncThunk(
  actions.GETALLDATA,
  async (payload) => {
    const response = await allData('all', payload);
    return response;
  }
);

export const resendEnvelope = createAsyncThunk(
  actions.RESEND_ENVELOPE,
  async (payload) => {
    const response = await resend(`resend?id=${payload}`, payload);
    return response;
  }
);

export const getHistoryList = createAsyncThunk(
  actions.GET_HISTORY,
  async (payload) => {
    const response = await history(`history/${payload}`, payload);
    return response;
  }
);

export const exportcsv = createAsyncThunk(
  actions.EXPORT_CSV,
  async (payload) => {
    const response = await history(`download-csv/${payload}`, payload);
    return response;
  }
);

export const getTrackersSlice = createSlice({
  name: 'trackersList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrackersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTrackersList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.all_trackers = data ? data : [];
        state.pagination = pagination;
        state.loading = false;
      })
      .addCase(getTrackersList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.all_data = data ? data : [];
        state.pagination = pagination;
        state.loading = false;
      })
      .addCase(getAllData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getHistoryList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHistoryList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.history_data = data ? data : [];
      })
      .addCase(getHistoryList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(exportcsv.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportcsv.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        if (success) {
          message.success(msg);
        }
      })
      .addCase(exportcsv.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(resendEnvelope.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendEnvelope.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        if (success) {
          message.success(msg);
        }
      })
      .addCase(resendEnvelope.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

/* export const getTrackersActions = getTrackersSlice.actions; */
/* export const { resetTrackers } = getTrackersSlice.actions; */

export default getTrackersSlice.reducer;
